import * as React from 'react';

const PrivacyPageEn = () => {
	return (
		<>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />
			<title>Data protection information for the Smart Kitchen Dock app</title>
			<style
				dangerouslySetInnerHTML={{
					__html: '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:PMingLiU;\n\tpanose-1:2 2 5 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:"Arial Black";\n\tpanose-1:2 11 10 4 2 1 2 2 2 4;}\n@font-face\n\t{font-family:Tahoma;\n\tpanose-1:2 11 6 4 3 5 4 4 2 4;}\n@font-face\n\t{font-family:"\\@PMingLiU";\n\tpanose-1:2 1 6 1 0 1 1 1 1 1;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Times New Roman",serif;}\np.MsoHeader, li.MsoHeader, div.MsoHeader\n\t{margin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Times New Roman",serif;}\np.MsoFooter, li.MsoFooter, div.MsoFooter\n\t{mso-style-link:"Footer Char";\n\tmargin:0cm;\n\tline-height:10.0pt;\n\tfont-size:8.0pt;\n\tfont-family:"Arial",sans-serif;}\np.MsoBodyText, li.MsoBodyText, div.MsoBodyText\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:9.0pt;\n\tmargin-left:0cm;\n\tline-height:13.0pt;\n\tfont-size:11.0pt;\n\tfont-family:"Times New Roman",serif;}\na:link, span.MsoHyperlink\n\t{color:blue;\n\ttext-decoration:underline;}\nspan.FooterChar\n\t{mso-style-name:"Footer Char";\n\tmso-style-link:Footer;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-size:11.0pt;}\n.MsoPapDefault\n\t{margin-bottom:10.0pt;\n\tline-height:115%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:21.0cm 841.95pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
				}}
			/>
			<div className="WordSection1">
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Data protection information
						</span>
					</b>
				</p>
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							for the Smart Kitchen Dock app{' '}
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '12.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Home Connect GmbH, located at Carl-Wery-Straße 34, 81739 Munich, Germany (hereinafter "Home Connect" or "We") is the data controller for
						the collection, processing and use of your personal data in connection with the Smart Kitchen Dock App (hereinafter "App"). The Smart
						Kitchen Dock App is only compatible with the Smart Kitchen Dock, i.e. it only works with the Smart Kitchen Dock. It is not interoperable
						with other devices (e.g. other Smart Speakers or docking stations).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We collect, process and use personal data that is either entered by you or otherwise created and processed in the course of using the
						App, the associated home appliances or services offered by Home Connect in accordance with the applicable data protection laws and
						inform you below about the data processing carried out by us on the basis of the usage agreement existing with you or any other legal
						basis.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							1.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Personal data categories
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In connection with the use of the Smart Kitchen Dock App, the Smart Kitchen Dock and the Home Appliances connected thereto via the Home
						Connect Services or other services and features offered by Home Connect (hereinafter "Services"), Home Connect collects, processes and
						uses the following categories of Personal Data:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Installation data, usage data,{' '}
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Data that we collect and use in connection with the use of user accounts and the setup of the app are:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Use of location data of the terminal device for setup and operation
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						As part of the setup of the Smart Kitchen Dock and App, we use the location data to scan the Bluetooth Low Energy (BLE) signals needed
						to set up and connect the Dock and App to the user's end device in use. In addition to initially establishing the connection, this is
						also used for functionality, where the user places the device into the Smart Kitchen Dock thereby causing the Smart Kitchen Dock App to
						launch automatically. This data is only used for direct use within the applications and is neither stored within the Smart Kitchen Dock
						app or the Smart Kitchen Dock nor transmitted to systems outside these applications.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Use of location data from the network (during setup)
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The transmission of location data from the network you are connected to is required to display the correct local time on the Smart
						Kitchen Dock App application and to show you the content shared for your location (e.g., Trending Topics) for display on the Home Screen
						of the Smart Kitchen Dock App, as well as to display the correct Home Connect customer service contact information based on the user's
						country.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						This network IP location is performed once during each setup (e.g. again after a factory reset) within Smart Kitchen Dock app. and uses
						for this purpose the IP address once in the context of a data transmission to a service provider in order to be able to store the
						country code.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Information that you provide as part of the connection with the Home Connect as well as an Amazon Alexa user account, although this is
						not stored in the device, but via a token transmitted by the respective provider (for these user accounts, the separate terms of use and
						privacy information of the respective providers apply in each case).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Information that we collect and temporarily use in connection with the ongoing use of the app:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Location data (see description above)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Gesture Recognition by Motion Sensor processing
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '3.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Information that we collect and store in connection with the ongoing use of the app:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						User account settings
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Identifier of the mobile device
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						App settings and status of the tracking setting{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Interaction with Trending Topics ("Smart Kitchen Dock Inspiration") and "Smart Kitchen Dock Navigation" (e.g. frequency of use)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '71.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Interaction with the app's functionalities (e.g. use of which pages/functions in statistical terms).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '53.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Connected services, shared use with third parties
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Once you have set up the Smart Kitchen Dock via the Smart Kitchen Dock app on the mobile device and connected it to your Home Connect
						account or your Alexa account ("connected services"), the functionalities of the Smart Kitchen Dock (e.g. voice control) are provided
						via an internet connection of the Smart Kitchen Dock via the local Wi-Fi network. This is done using the device data as well as the,
						commands processed via Alexa. The control is initially carried out via the app, but the data is not processed directly in the app;
						instead, it is processed by the connected service. Therefore, please refer to the respective privacy information of the connected
						services.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Once you have logged in with your Amazon Alexa and your Home Connect user account, the Smart Kitchen Dock will remain connected to your
						Amazon Alexa and your Home Connect user account until you actively disconnect it again via the Smart Kitchen Dock app or you reset the
						Smart Kitchen Dock to factory settings. Removing the mobile device will not disconnect your Amazon Alexa and Home Connect user account.
						Please refer to the notes in the Terms of Use for more information.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you allow third parties to use the Smart Kitchen Dock, you undertake to inform these third parties prior to use about the purpose and
						scope of the data collection and the further processing and use by the operator and Amazon and to obtain the consent of all users to
						this data handling.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							c.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								App usage data
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						App usage data is data from your interaction with the app such as app functionalities used, click behavior related to app controls,
						selections in drop-down menus, on/off switch settings and app malfunction reports (if feature available). See also below{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						6
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							2.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Purpose
						</span>
					</b>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We use the above categories of data{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						to provide the functionalities of the App as well as the services offered via the App (1.a.-c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						to improve the user-friendliness of the App and for specific or general troubleshooting in the App and the digital services offered via
						it (1. a to c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						to improve the product and service offerings of the Operator and the Operator's Affiliates, in particular with respect to the Smart
						Kitchen Dock itself and with respect to unused features and other functions of the App and connected home appliances (including
						algorithmic learning) (1. a to c. ),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Contacting you in the event of safety notices and product recalls via the Smart Kitchen Dock app (including providing in-app/email
						messages) (1.a.-c.),
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						and
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						for marketing and market research purposes (including the provision of in-app/email messages) (1.a.-c.), insofar and as long as a
						corresponding consent has been given.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The legal bases for the respective processing purposes within the scope of the GDPR (REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT
						AND OF THE COUNCIL of 27 April 2016) can be found in section 12 of this information.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							3.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Rule storage periods
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Subject to deviating legal requirements, the following standard storage periods shall apply:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Location data:
							</span>
						</u>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						No permanent storage inside or outside the application, use is only for triggering specific actions as described in 1 a. above.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Usage data:
							</span>
						</u>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Deletion with deletion of the local user account or that of the connected user accounts.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							c.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								App usage data:
							</span>
						</u>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Storage in pseudonymous form and provision in personalized form for services/messages provided via the app if the "Allow tracking of
						usage data" function is activated. When the function is deactivated, the individual ID used for tracking is reset so that app usage data
						that has already been collected can no longer be assigned to you.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							4.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Control of the data processing
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Connectivity of your home appliance
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can control the connectivity of your home appliance through the app. To use the features provided by the Smart Kitchen Dock and the
						Smart Kitchen Dock App, information (e.g., your user IDs; requests made; and your Home Appliance control instructions) is transmitted
						via a data connection to servers used to process the requests ("Amazon Server") and to carry out your control instructions ("Home
						Connect Server").
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.15pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								User accounts and local app data
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Through the app, you can control your user accounts and delete app data held locally.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can reset the Smart Kitchen Dock to the factory default state. In this case, the Smart Kitchen Dock will be unlinked from your Smart
						Kitchen Dock app and your Alexa and Home Connect user account.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '35.7pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						By deleting the app, you remove all locally stored user-related data, but not the created accounts.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							5.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Transfer or disclosure of your data, e.g. to third parties
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To provide the app and the services offered via it, we work together with various service providers. Insofar as we have obligated these
						service providers to process data strictly in accordance with instructions, data processing by these service providers does not require
						your consent.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Service providers that we use as processors:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '53.25pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-35.25pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						•
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Service provider for hosting services (including for storage)
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '53.25pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-35.25pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						•
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Service provider for programming services
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '53.25pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-35.25pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						•
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Service provider for hotline services and other app services.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We only transfer your data to other recipients if this is necessary for the fulfillment of a contract with you or between you and the
						third-party provider, if we or the recipient have a legitimate interest in the transfer of your data, or if you have consented to the
						transfer. These recipients include service providers and also other companies of the group companies associated with the operator. In
						addition, data may be transferred to other recipients if we are required to do so by law or by enforceable official or court order.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Other recipients of your data include, in particular, service providers whose offers and services (third-party services) Smart Kitchen
						Dock mediates within the scope of the app or enables access to such services (see 9. below).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Content, in particular recipes that you have created yourself, may also be transferred to other BSH companies (also internationally) as
						part of technical processing and provision. If we carry out such transfer/processing, this will be done on the basis of agreements under
						data protection law between the BSH companies involved, which also include the application of standard contractual clauses, insofar as
						the transfer is outside the scope of the GDPR and there is no case of Art. 45 GDPR.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<a name="_Ref457484543">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								6.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								App usage recording
							</span>
						</b>
					</a>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The app offers the possibility to collect app usage data (see above 1.d.) and uses the following providers for this purpose:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-1.0cm'
					}}
				>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Adobe Analytics from Adobe Systems Software Ireland Limited, 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland
						(hereinafter "Adobe").
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If the "Allow tracking of usage data" function is activated, app usage data will be sent to and stored on servers of the aforementioned
						providers located in the EU. The app usage data enables an analysis of your use of the app and the provision of notifications for
						services provided via the app (see 1.c. above). IP anonymization has been activated for this app, so that the IP address you use is
						shortened beforehand. On behalf of Smart Kitchen Dock, the respective provider will use this information on our behalf to evaluate your
						use of the App, to generate error reports and to compile reports on App activities for Smart Kitchen Dock. The IP address and other
						personal data transmitted by your mobile device as part of the aforementioned services will not be merged with other data from the
						aforementioned providers or Smart Kitchen Dock without your separate consent.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can control the collection of app usage data (including your IP address) by the aforementioned services, as well as the processing
						of this data by the aforementioned services, by activating or deactivating the "Allow tracking of usage data" function. Depending on the
						legal situation in your country, the function "Send app usage data anonymously for service improvement" may be active by default.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In addition, if you agree, incident reports from the app may be sent to us so that we can take specific action to resolve such
						incidents.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt',
						pageBreakAfter: 'avoid'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							7.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Data security
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In order to protect your data from manipulation, loss and unauthorized access by third parties, for example, we use technical and
						organizational measures. These measures include the use of encryption technology,{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						certificates, the use of a firewall on the Smart Kitchen Dock server and password protection of the Smart Kitchen Dock app.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We continuously review and improve our security measures in line with technological progress.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							8.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Scope of the information on data protection
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						This information on data protection applies to the services offered by Smart Kitchen Dock via the app. If additional functions, benefits
						or services are offered by Smart Kitchen Dock or a BSH Hausgeräte Group company within the app, special information on data protection
						will be provided for them, insofar as their use is subject to special data protection regulations.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						This information on data protection does not apply to third-party services, even if Smart Kitchen Dock arranges the use of or access to
						these third-party services within the framework of the app (for third-party services, see also the Terms of Use). In this case, the use
						of these third-party services is governed by the respective data protection provisions of the service provider and, if applicable,
						additional information on data protection on our part, which addresses the specifics of these third-party services and is only relevant
						to this extent.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In the event of onward transfer to another service provider, Smart Kitchen Dock shall make reasonable and appropriate efforts to
						identify the fact of onward transfer (e.g. when embedding the content of the service provider within the app via inline frames), insofar
						as the onward transfer is not obvious. Obviousness is given, for example, if the Smart Kitchen Dock app is left via a link and the app
						or website of another service provider is called up.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you are a user within the scope of the GDPR, please note that the use of third-party services may result in your personal data being
						processed in countries outside the scope of the GDPR. In this regard, please refer to the data protection information of the respective
						third-party service provider.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							9.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;Change of the information on data protection
						</span>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In the course of the further development of the app - among other things due to the implementation of new technologies or the
						introduction of new services - it may become necessary to adapt this information on data protection. Smart Kitchen Dock is entitled to
						make changes to the information on data protection as well as to the special information on data protection for the future: this applies
						in particular if the operator provides further services as part of an update.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Changes to the respective information on data protection, which relate to changes in the contractual relationship (e.g. by changing the
						services or the terms of use), we will notify you together with the notes on the respective changes to the terms of use, so that you are
						informed in good time in each case. Insofar as we adapt the data protection information without changes to the contractual relationship,
						we will make you aware of the changes in an appropriate manner.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Editorial changes to these Terms of Use, i.e. changes that do not affect the contractual relationship, such as the correction of
						typographical errors, will be made without notification.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							10.
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Rights and contact information
						</span>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						If, despite our efforts to ensure that the data is correct and up to date, incorrect information about you is stored, we will correct it
						at your request. If you have given us your consent to the collection, processing and use of your personal data, you can revoke this at
						any time with effect for the future. You can usually exercise the revocation by pressing the corresponding setting within the app,
						otherwise by using the contact options mentioned in the app.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Your personal data will be deleted if you revoke your consent to storage, if knowledge of the personal data is no longer required to
						fulfill the purpose for which it was stored, or if storage is inadmissible for other legal reasons. Please take into account that for
						technical or organizational reasons, there may be an overlap between your revocation and the use of your data, e.g. in the context of a
						newsletter that has already been sent. Data that is required for billing and accounting purposes or is subject to the legal obligation
						to retain data is not affected by this.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span lang="EN-US">&nbsp;</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you have any questions about data protection or wish to exercise your rights under data protection law to revoke consent, obtain
						information, correct, delete or block data, please contact us using the contact information provided in the app.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '0cm',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginLeft: '14.2pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-14.2pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							11.
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Supplementary information according to the GDPR{' '}
						</span>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							a)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Legal basis for data processing
							</span>
						</u>
					</b>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We base the following data processing on{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						a legal permission according to Art. 6 para.{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						1 lit. b GDPR:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Provision of the functionalities of the App as well as the services offered via the App by processing the data categories 1.a.-c.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Transfer of the data categories according to 1.a.-c. to service providers of third-party services that Smart Kitchen Dock mediates in
						the context of the App (see 9. below).
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Elimination of disturbances caused by processing of data categories 1.b. -d.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						a legal permission according to Art. 6 para.{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						1 lit. f GDPR:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Improving the user experience of the app by processing data categories 1.d. (applies to those jurisdictions that allow the collection of
						app usage data based on legitimate interests. The "Allow tracking of usage data" feature is active by default in this case. )
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Improving our product and service offering, in particular with regard to unused programs or frequently used programs and other functions
						of the app and home device by processing data categories 1.c. and d.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '72.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '36.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Your consent according to Art. 6 para.{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						1 lit. a GDPR:
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							b)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Your rights
							</span>
						</u>
					</b>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In addition to Section 11, you have the following rights. To exercise your right, please use the contact details provided at the end of
						this information on data protection.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to information about your data:{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Upon request, we will provide you with information about the personal data we have stored about you.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to correct or complete your data:{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We will correct incorrect personal data if you inform us accordingly. We will complete incomplete data upon your notification if this is
						necessary for the purposeful processing of your data.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to have your data deleted:{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We will delete personal data stored by us upon request. For some data, however, the deletion takes place only after a specified
						retention period, because, for example, the law obliges us in some cases to retain the data or we need the data for the processing of
						the contractual relationship with you.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Please also refer to section 11.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to block your data:{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In certain cases defined by law, we will block your data if you so request. Blocked data will only be processed to a very limited
						extent.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to revoke consent:{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can revoke your consent to the processing of your data at any time with effect for the future. The lawfulness of the processing of
						your data until the revocation remains unaffected.{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Please also refer to section 11.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to object{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						to the <b>processing of your data: </b>You may object to the processing of your data at any time with effect for the future if we
						process your data on one of the legal bases according to Art. 6 (1) lit. e or f GDPR. In the event of an objection, we will cease
						processing your data if there are no compelling legitimate grounds for further processing. Compelling reasons worthy of protection never
						exist for us if we process your data for the purpose of direct marketing.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right to data portability: At your{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						request, we can provide you with certain data in a structured, common and machine-readable format.&nbsp;{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-17.85pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: 'Symbol' }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Your right of appeal to the supervisory authority:{' '}
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can also submit complaints of a data protection nature to a data protection authority. To do so, contact the data protection
						authority responsible for your place of residence or the data protection authority responsible for us below.&nbsp;{' '}
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Bavarian State Office for Data Protection Supervision,{' '}
					</span>
					<span lang="EN-US">
						<a href="http://www.baylda.de">
							<span style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>www.baylda.de</span>
						</a>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '32.05pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							c)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Disclosure of personal data to recipients outside the EEA
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If necessary for the provision of the functionalities of the app as well as the services offered via the app, we also disclose personal
						data to recipients that are based outside the EEA in so-called third countries. In this case, we ensure before the transfer that either
						an adequate level of data protection exists at the recipient (e.g. based on an adequacy decision of the EU Commission for the respective
						country or the agreement of so-called EU standard contractual clauses of the European Union with the recipient) or your consent to the
						transfer has been obtained.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can obtain from us an overview of the recipients in third countries and a copy of the specifically agreed arrangements for ensuring
						the appropriate level of data protection. To do so, use the contact details provided at the end of this information on data protection.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '0cm',
						marginLeft: '17.85pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Please also refer to section 9 for the transfer of personal data when using third-party services.
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginBottom: '6.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoBodyText"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						textIndent: '-18.0pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							d)
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<u>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Contact details of the data protection officer
							</span>
						</u>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						In all questions on the subject of data protection and the exercise of your rights, you can contact our data protection officer directly
						via the following contact details:
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: '18.0pt',
						textAlign: 'justify',
						textJustify: 'inter-ideograph',
						lineHeight: '13.5pt',
						background: 'white'
					}}
				>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						BSH Hausgeräte GmbH
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Datenschutzbeauftragter
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Carl-Wery-Str. 34
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						81739 Munich
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span
						lang="DE"
						style={{
							fontSize: '10.0pt',
							fontFamily: '"Arial",sans-serif',
							color: 'black'
						}}
					>
						Germany
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '18.0pt' }}>
					<span lang="EN-US">
						<a href="mailto:Data-Protection-DE@bshg.com">
							<span
								lang="DE"
								style={{
									fontSize: '10.0pt',
									fontFamily: '"Arial",sans-serif',
									textDecoration: 'none'
								}}
							>
								Data-Protection-DE@bshg.com
							</span>
						</a>
					</span>
				</p>
				<p className="MsoBodyText" style={{ textAlign: 'justify', textJustify: 'inter-ideograph' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" align="right" style={{ marginBottom: '10.0pt', textAlign: 'right', lineHeight: '115%' }}>
					<span
						lang="EN-US"
						style={{
							fontSize: '10.0pt',
							lineHeight: '115%',
							fontFamily: '"Arial",sans-serif'
						}}
					>
						Version: V1.0.0 September 2022
					</span>
				</p>
			</div>
		</>
	);
};

export default PrivacyPageEn;
